<template>
  <div>
    <!-- Return Home -->
    <div class="mb-2">
      <a href="/" class="btn btn-link btn-block">Return Home</a>
    </div>
    <OtpDevices
      :preferredAuthMethod="localPreferredAuthMethod"
      @update-preferred-auth-method="updatePreferredAuthMethod"
      :emailConfigured="emailConfigured"
      :phoneConfigured="phoneConfigured"
    />

    <!-- If two-factor recently disabled -->
    <template v-if="recentlyDisabled">
      <div class="alert alert-success" role="alert">
        Usage of the Authenticator-app has been disabled. The 'Email' method has
        been enabled by default.
      </div>
    </template>

    <!-- If two-factor enabled -->
    <template
      v-if="
        twoFactorAuthenticationStatus === 'enabled' ||
        twoFactorAuthenticationStatus === 'configured'
      "
    >
      <div class="card">
        <!-- If two-factor enabled for Authenticator app, no configuration necessary, just enable-->
        <div class="card-header">
          <div class="row align-items-center">
            <div v-if="localPreferredAuthMethod !== 'app'" class="col">
              <i class="fas fa-check-circle"></i>
              Authenticator App is configured, available to enable.
            </div>
            <div v-else class="col">
              <i class="fas fa-check-circle text-alpha_success"></i>
              Two-Factor is enabled.
            </div>
            <div class="col-auto">
              <form
                method="POST"
                :action="route('auth.management.enable-two-factor-no-config')"
                id="two-factor-no-config"
                @submit.prevent="enableTwoFactorNoConfig"
              >
                <CSRFTokenInput />

                <!-- Hidden input to send additional data -->
                <input type="hidden" name="preferred_method" value="app" />
                <button
                  type="submit"
                  :class="{
                    'btn btn-primary': localPreferredAuthMethod !== 'app',
                    'btn btn-success': localPreferredAuthMethod === 'app'
                  }"
                  :disabled="localPreferredAuthMethod === 'app'"
                >
                  {{
                    localPreferredAuthMethod === "app" ? "Enabled" : "Enable"
                  }}
                </button>
              </form>
            </div>
          </div>
        </div>

        <!-- If two-factor recently confirmed -->
        <template v-if="recentlyConfirmed">
          <div class="card mb-3 border-alpha_success">
            <div class="card-header">
              Two-factor authentication setup complete.
            </div>

            <div class="card-body">
              <p>
                <strong>
                  Please take a moment to generate and save your recovery codes.
                </strong>
              </p>
            </div>
          </div>
        </template>

        <!-- Backup Code Information -->
        <div class="card">
          <div class="card-header">Recovery Codes</div>

          <div class="card-body">
            <p>
              Two-factor recovery codes may be used to recover your account in
              the event you lose access to your two-factor authentication
              device.
            </p>

            <p>Save your recovery codes in a secure location.</p>

            <p class="mb-4">
              <em>
                Generating new recovery codes will invalidate any existing
                recovery codes.
              </em>
            </p>

            <RecoveryCodes />
          </div>
        </div>

        <!-- Disable two-factor authentication -->
        <div class="card">
          <div class="card-header">
            <i class="fas fa-exclamation-triangle text-alpha_danger"></i>
            Remove Authenticator App Configuration
          </div>

          <div class="card-body">
            <p class="card-text">
              By removing the authenticator app configuration, you will no
              longer be able to login to this account with an authenticator app.
              The 'Email' method will be enabled by default.
            </p>

            <p class="mb-3">
              You will be prompted to enter your password. Once confirmed, you
              may continue to remove your Authenticator-app configuration.
            </p>

            <form
              method="POST"
              :action="route('two-factor.enable')"
              onsubmit="return confirm(
                        'You are about to disable two-factor authentication. '
                            + '\n\nClick OK to continue.'
                    )"
            >
              <CSRFTokenInput />
              <input type="hidden" name="_method" value="DELETE" />

              <button type="submit" class="btn btn-alpha_danger spin-on-click">
                Remove Two-Factor Authentication Configuration
                <i class="fas fa-spinner-third fa-spin d-none"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </template>

    <!-- Elseif two-factor pending confirmation -->
    <template
      v-else-if="twoFactorAuthenticationStatus === 'pending-confirmation'"
    >
      <div class="card mb-3">
        <div class="h5 card-header">Configure Two-Factor Authentication</div>

        <div class="card-body">
          <p>
            To complete setup, please scan the following QR code with your
            phone's authenticator application.
          </p>

          <p class="mb-3">
            Once scanned, enter the code generated by your authenticator
            application to confirm two-factor authentication setup.
          </p>

          <div class="d-flex justify-content-center">
            <div class="bg-white p-2" v-html="twoFactorQrCodeSvg"></div>
          </div>

          <!-- Confirm two-factor authentication -->
          <form method="POST" :action="route('two-factor.confirm')">
            <CSRFTokenInput />

            <div class="form-group mt-4">
              <label for="recovery_code">Recovery Code</label>

              <input
                id="recovery_code"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': twoFactorConfirmationError
                }"
                name="code"
                required
              />

              <div
                v-if="twoFactorConfirmationError"
                class="invalid-feedback"
                role="alert"
              >
                {{ twoFactorConfirmationError }}
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
            >
              Confirm
              <i class="fas fa-spinner-third fa-spin d-none"></i>
            </button>
          </form>

          <!-- Cancel -->
          <form method="POST" :action="route('two-factor.enable')">
            <CSRFTokenInput />
            <input type="hidden" name="_method" value="DELETE" />

            <button
              type="submit"
              class="btn btn-block btn-link text-alpha_danger spin-on-click"
            >
              Cancel Setup
              <i class="fas fa-spinner-third fa-spin d-none"></i>
            </button>
          </form>
        </div>
      </div>
    </template>

    <!-- Else-if Password was recently confirmed, but not for disabling purposes -->
    <template
      v-else-if="passwordConfirmedAt !== '' && preferredAuthMethod !== 'app'"
    >
      <div class="card mb-3">
        <div class="h5 card-header">Configure Two-Factor Authentication</div>

        <div class="card-body">
          <p class="mb-3">
            Password confirmed! Now you may continue to configure your device.
          </p>

          <form method="POST" :action="route('two-factor.enable')">
            <CSRFTokenInput />

            <button
              type="submit"
              class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
            >
              Configure Two-Factor Authentication
              <i class="fas fa-spinner-third fa-spin d-none"></i>
            </button>
          </form>
        </div>
      </div>
    </template>

    <!-- Else two-factor not enabled -->
    <template v-else>
      <div class="card mb-3">
        <div class="h5 card-header">Configure Two-Factor Authentication</div>

        <div class="card-body">
          <p>
            Enabling two-factor authentication will allow you to securely login
            to your account using your phone's authenticator application, such
            as Google Authenticator or Authy.
          </p>
          <p class="mb-3">
            By clicking the button below, you will need to confirm your
            password. If successful, you will return here to continue to
            configure your device for Two-Factor authentication.
          </p>

          <form method="POST" :action="route('two-factor.enable')">
            <CSRFTokenInput />

            <button
              type="submit"
              class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
            >
              Configure Two-Factor Authentication
              <i class="fas fa-spinner-third fa-spin d-none"></i>
            </button>
          </form>
        </div>
      </div>
    </template>

    <!-- Return Home -->
    <div>
      <a href="/" class="btn btn-link btn-block">Return Home</a>
    </div>
  </div>
</template>

<script>
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";
import RecoveryCodes from "@/components/auth/TwoFactorManagement/RecoveryCodes.vue";
import OtpDevices from "@/components/auth/OtpDevices/index.vue";
import axios from "axios";
export default {
  components: {
    CSRFTokenInput,
    RecoveryCodes,
    OtpDevices
  },

  props: {
    recentlyConfirmed: {
      type: Boolean,
      default: false
    },

    recentlyDisabled: {
      type: Boolean,
      default: false
    },

    twoFactorConfirmationError: {
      type: String,
      default: ""
    },

    twoFactorQrCodeSvg: {
      type: String,
      default: ""
    },

    twoFactorAuthenticationStatus: {
      type: String,
      required: true
    },

    preferredAuthMethod: {
      type: String
    },

    emailConfigured: {
      type: String
    },

    phoneConfigured: {
      type: String
    },
    passwordConfirmedAt: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      localPreferredAuthMethod: this.preferredAuthMethod // Sync local state with prop
    };
  },

  methods: {
    // Handle the update from the child
    updatePreferredAuthMethod(newMethod) {
      this.localPreferredAuthMethod = newMethod;
    },

    async enableTwoFactorNoConfig(event) {
      const form = event.target;
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.updatePreferredAuthMethod("app");
        })
        .catch((error) => {
          if (error.response) {
            this.smsMessage = error.response.data.message;
            this.smsMessageTypeClass = "alert-danger";
          }
        });
    }
  }
};
</script>
