<template>
  <div>
    <!-- Email Card -->
    <template>
      <div class="card mb-3">
        <!-- If two-factor enabled for email-->
        <div v-if="localEmailConfigured" class="card mb-3">
          <div class="card-header">
            <div class="row align-items-center">
              <div v-if="localPreferredAuthMethod !== 'email'" class="col">
                <i class="fas fa-check-circle"></i>
                Email is configured, available to enable.
              </div>
              <div v-else class="col">
                <i class="fas fa-check-circle text-alpha_success"></i>
                Email is enabled.
              </div>
              <div class="col-auto">
                <form
                  method="POST"
                  :action="route('auth.management.update-preferred-method')"
                  id="email"
                  @submit.prevent="configureEmail"
                >
                  <CSRFTokenInput />

                  <!-- Hidden input to send additional data -->
                  <input type="hidden" name="preferred_method" value="email" />
                  <button
                    type="submit"
                    :class="{
                      'btn btn-primary': localPreferredAuthMethod !== 'email',
                      'btn btn-success': localPreferredAuthMethod === 'email'
                    }"
                    :disabled="localPreferredAuthMethod === 'email'"
                  >
                    {{
                      localPreferredAuthMethod === "email"
                        ? "Enabled"
                        : "Enable"
                    }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="h5 card-header">Configure Email Authentication</div>
        <div class="card-body">
          <p class="mb-3">
            Email authentication is configured and enabled by default. You will
            receive a one-time password in your email inbox. Only email
            addresses registered with this application will be allowed, no
            configuration necessary.
          </p>

          <!-- Displaying response or error messages -->
          <div
            v-if="emailMessage"
            :class="['alert text-center', emailMessageTypeClass]"
            role="alert"
          >
            {{ emailMessage }}
          </div>
        </div>
      </div>
    </template>
    <!-- SMS Card -->
    <template>
      <div class="card mb-3">
        <!-- If two-factor configged for SMS, just enable-->
        <div v-if="localPhoneConfigured" class="card mb-3">
          <div class="card-header">
            <div class="row align-items-center">
              <div v-if="localPreferredAuthMethod !== 'sms'" class="col">
                <i class="fas fa-check-circle"></i>
                SMS is configured, available to enable.
              </div>
              <div v-else class="col">
                <i class="fas fa-check-circle text-alpha_success"></i>
                SMS is enabled.
              </div>
              <div class="col-auto">
                <form
                  method="POST"
                  :action="route('auth.management.enable-phone-no-config')"
                  id="sms-no-config"
                  @submit.prevent="enablePhoneNoConfig"
                >
                  <CSRFTokenInput />

                  <!-- Hidden input to send additional data -->
                  <input type="hidden" name="preferred_method" value="sms" />
                  <button
                    type="submit"
                    :class="{
                      'btn btn-primary': localPreferredAuthMethod !== 'sms',
                      'btn btn-success': localPreferredAuthMethod === 'sms'
                    }"
                    :disabled="localPreferredAuthMethod === 'sms'"
                  >
                    {{
                      localPreferredAuthMethod === "sms" ? "Enabled" : "Enable"
                    }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- If two-factor is not configured yet for SMS, User can config and enable -->
        <div v-else class="h5 card-header">Configure SMS Authentication</div>

        <div class="card-body">
          <p class="mb-4">
            SMS authentication will allow you to receive one-time passwords via
            text-message. You can always reconfigure a different phone number by
            clicking below.
          </p>

          <form
            method="POST"
            :action="route('auth.management.update-preferred-method')"
            id="sms"
            @submit.prevent="configureSms"
          >
            <CSRFTokenInput />

            <!-- Hidden input to send additional data -->
            <input type="hidden" name="preferred_method" value="sms-config" />
            <button
              :disabled="
                showMissingPhoneNumberInput || showPhoneVerificationInput
              "
              type="submit"
              class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
            >
              Configure SMS Authentication
              <i class="fas fa-spinner-third fa-spin d-none"></i>
            </button>
          </form>

          <form
            v-if="localPhoneConfigured"
            method="POST"
            :action="route('auth.management.remove-phone-config')"
            id="remove-phone-config"
            @submit.prevent="removePhoneConfig"
          >
            <CSRFTokenInput />

            <!-- Hidden input to send additional data -->
            <input type="hidden" name="remove_sms" value="remove-phone-config" />
              <button
                type="submit"
                class="btn btn-block btn-link text-alpha_danger spin-on-click"
              >
                Remove this device
              </button>
          </form>
          <!-- Displaying response or error messages -->
          <div
            v-if="smsMessage"
            :class="['mt-2 alert text-center', smsMessageTypeClass]"
            role="alert"
          >
            {{ smsMessage }}
          </div>

          <div v-if="showMissingPhoneNumberInput">
            <form
              method="POST"
              :action="route('auth.management.add-phone-number')"
              id="add-phone-number"
              @submit.prevent="addPhoneNumber"
            >
              <CSRFTokenInput />

              <div class="form-group card-body">
                <label>Please enter your mobile phone number.</label>
         
                <input
                  type="text"
                  id="phone-number"
                  class="form-control"
                  v-model="phoneNumber"
                  maxlength="12" 
                  placeholder="Enter your phone number"
                  @focus="clearMessages"
                />
              </div>
              <button
                type="submit"
                class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
              >
                Submit Phone Number
              </button>
              <button
                @click="closePhoneNumberInput"
                type="text"
                class="btn btn-block btn-link text-alpha_danger spin-on-click"
              >
                Cancel
              </button>
            </form>
          </div>
          <div v-if="showPhoneVerificationInput">
            <p class="mt-4 mb-2">
              Please enter the one-time password sent your mobile phone:
              <strong>{{ formattedPhone  }}</strong>
            </p>

            <p
              href="javascript:void(0);"
              data-toggle="tooltip"
              title="Check that your phone number is entered correctly. It may take several minutes for the text to arrive. You can always refresh this page, which resets the form to use your old Authentication method."
            >
              Didn't receive a text?
              <i class="fas fa-question-circle ml-1 text-info"></i>
            </p>

            <form
              method="POST"
              :action="route('auth.management.verify-phone-number-with-otp')"
              id="verify-phone-number"
              @submit.prevent="verifyPhoneNumberWithOtp"
            >
              <CSRFTokenInput />
              <div class="form-group">
                <input
                  type="text"
                  id="otp"
                  class="form-control"
                  v-model="otp"
                  maxlength="6"
                  placeholder="Enter One Time Password"
                  @focus="clearMessages"
                />
              </div>
              <button
                type="submit"
                class="btn btn-alpha_primary btn-lg btn-block spin-on-click"
              >
                Submit One Time Password
              </button>
              <button
                @click="closeOtpVerficationInput"
                type="text"
                class="btn btn-block btn-link text-alpha_danger spin-on-click"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";
import axios from "axios";
export default {
  components: {
    CSRFTokenInput
  },

  // Local state
  data() {
    return {
      localPreferredAuthMethod: this.preferredAuthMethod,
      localEmailConfigured: this.emailConfigured,
      localPhoneConfigured: this.phoneConfigured,
      emailMessage: "",
      emailMessageTypeClass: "",
      smsMessage: "",
      smsMessageTypeClass: "",
      showMissingPhoneNumberInput: false,
      showPhoneVerificationInput: false,
      phoneNumber: "",
      otp: ""
    };
  },

  props: {
    preferredAuthMethod: {
      type: String
    },

    emailConfigured: {
      type: String
    },

    phoneConfigured: {
      type: String
    }
  },

  computed: {
    formattedPhone() {
      // Remove non-numeric characters
      let value = this.phoneNumber.replace(/\D/g, '');
      // Apply formatting -- <input> is maxxed at 12 chars
      if (value.length > 6) {
        value = value.replace(/^(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
      } else if (value.length > 3) {
        value = value.replace(/^(\d{3})(\d+)/, '$1-$2');
      }
      return value;
    }
  },

  watch: {
    preferredAuthMethod(newValue) {
      this.localPreferredAuthMethod = newValue;
    },
    phoneConfigured(value) {
      this.localPhoneConfigured = value;
    },
    phoneNumber() {
      this.phoneNumber = this.formattedPhone;
    }
  },

  methods: {
    updateAuthMethod(newMethod) {
      this.localPreferredAuthMethod = newMethod; // Update locally
      this.$emit("update-preferred-auth-method", newMethod); // Notify parent
    },

    closePhoneNumberInput() {
      this.showMissingPhoneNumberInput = false;
    },

    closeOtpVerficationInput() {
      this.showPhoneVerificationInput = false;
    },

    async configureEmail(event) {
      this.clearMessages();
      const form = event.target;
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.updateAuthMethod("email");
        })
        .catch((error) => {
          if (error.response) {
            this.emailMessage = error.response.data.message;
            this.emailMessageTypeClass = "alert-danger";
          }
        });
    },

    async enablePhoneNoConfig(event) {
      this.clearMessages();
      const form = event.target;
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.updateAuthMethod("sms");
        })
        .catch((error) => {
          if (error.response) {
            this.smsMessage = error.response.data.message;
            this.smsMessageTypeClass = "alert-danger";
          }
        });
    },

    async configureSms(event) {
      this.clearMessages();
      const form = event.target;
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.smsMessage = response.data.message;
          this.smsMessageTypeClass = "alert-success";
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message == "Update or add phone number.") {
              this.showMissingPhoneNumberInput = true;

              this.$nextTick(() => {
                document.getElementById("phone-number").focus();
              });
            } else {
              this.smsMessage = error.response.data.message;
              this.smsMessageTypeClass = "alert-danger";
            }
          }
        });
    },

    async addPhoneNumber(event) {
      const form = event.target;
      const formData = new FormData(form);
      formData.append("phone_number", this.phoneNumber);

      await axios
        .post(form.action, formData)
        .then((response) => {
          this.showMissingPhoneNumberInput = false;
          this.showPhoneVerificationInput = true;

          this.$nextTick(() => {
                document.getElementById("otp").focus();
              });
        })
        .catch((error) => {
          if (error.response) {
            this.smsMessage = error.response.data.message;
            this.smsMessageTypeClass = "alert-danger";
          }
        });
    },

    async verifyPhoneNumberWithOtp(event) {
      const form = event.target;
      const formData = new FormData(form);
      formData.append("otp", this.otp);

      await axios
        .post(form.action, formData)
        .then((response) => {
          this.showPhoneVerificationInput = false;
          this.updateAuthMethod("sms");
          this.localPhoneConfigured = this.phoneNumber;
        })
        .catch((error) => {
          if (error.response) {
            this.smsMessage = error.response.data.message;
            this.smsMessageTypeClass = "alert-danger";
          }
        });
    },

    async removePhoneConfig(event) {
      this.closeOtpVerficationInput();
      this.closePhoneNumberInput();
      const form = event.target;
      const formData = new FormData(form);

      await axios
        .post(form.action, formData)
        .then((response) => {
          this.smsMessage = response.data.message;
          this.smsMessageTypeClass = "alert-success";
          setTimeout(() => {
            this.smsMessage = "";
            this.smsMessageTypeClass = "";
          }, 5000)
        
          this.updateAuthMethod("email");
          this.localPhoneConfigured = ""
        })
        .catch((error) => {
          if (error.response) {
            this.smsMessage = error.response.data.message;
            this.smsMessageTypeClass = "alert-danger";
            setTimeout(() => {
              this.smsMessage = "";
              this.smsMessageTypeClass = "";
            }, 5000)
          }
        })
    },  

    clearMessages() {
      this.emailMessage = "";
      this.emailMessageTypeClass = "";
      this.smsMessage = "";
      this.smsMessageTypeClass = "";
    }
  }
};
</script>
