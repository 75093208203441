<template>
  <div class="container col-xs-12 col-sm-8 col-md-8 col-lg-6">
    <template>
      <!-- OTP Submission Form -->
      <form
        id="otpForm"
        @submit.prevent="submitOtp"
        method="POST"
        :action="route('otp.submit')"
      >
        <CSRFTokenInput />

        <div class="form-group column mt-5">
          <div class="row justify-content-center">
            <h1>One Time Passcode</h1>
          </div>
          <div class="row justify-content-center mb-1">
            <p>Check your {{ preferredAuthMethod }} for the OTP code.</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <input
                id="otp"
                type="text"
                class="form-control"
                v-model="otp"
                name="otp"
                maxlength="6"
                required
                autofocus
                @click="
                  responseMessage = '';
                  responseMessageType = '';
                "
              />
              <span v-if="errors.otp" class="invalid-feedback" role="alert">
                <strong>{{ errors.otp }}</strong>
              </span>
              <div
                v-if="responseMessage"
                :class="['alert text-center mt-1', responseMessageType]"
                role="alert"
              >
                {{ responseMessage }}
              </div>
              <div
                v-if="csrfTokenMismatchError"
                class="alert alert-alpha_danger text-center mt-1"
                role="alert"
              >
                {{ csrfTokenMismatchError }}
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-3">
            <button
              type="submit"
              class="btn btn-alpha_primary d-flex align-items-center"
            >
              <span
                class="spinner-border spinner-border-sm d-none"
                role="status"
                aria-hidden="true"
                style="margin-right: 8px"
              ></span>
              Submit
            </button>
          </div>
        </div>
      </form>
    </template>
    <!-- Request OTP be sent to a different device form, if stored in session -->
    <template>
      <div v-if="secondaryAuthMethod">
        <form
          id="newMethodForm"
          method="POST"
          :action="route('otp.request-secondary-device')"
        >
          <CSRFTokenInput />

          <div class="row justify-content-center" v-if="secondaryAuthMethod">
            <a href="#" @click.prevent="requestSecondaryAuthMethodOtp">
              Didn't receive your OTP? Click here to receive an OTP via
              {{ secondaryAuthMethod }}.
            </a>
          </div>
        </form>
      </div>

      <div v-else>
        <form
          id="requestNewOtpForm"
          method="POST"
          :action="route('otp.request-new-otp')"
        >
          <CSRFTokenInput />

          <div class="row justify-content-center">
            <a href="#" @click.prevent="requestNewOtp">
              Didn't receive your OTP? Click here to receive another OTP via
              email.
            </a>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";

import axios from "axios";

export default {
  components: {
    CSRFTokenInput
  },

  props: {
    csrfTokenMismatchError: {
      type: String,
      default: ""
    },
    preferredAuthMethod: {
      type: String,
      required: true
    },
    secondaryAuthMethod: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      otp: "",
      errors: {},
      responseMessage: "",
      responseMessageType: ""
    };
  },

  methods: {
    /**
     * Handle OTP Verification Form Submission
     */
    async submitOtp(event) {
      const button = event.submitter;
      const spinner = button.children[0];

      spinner.classList.remove("d-none"); //set load-spinner
      button.disabled = true;

      const form = event.target;

      this.responseMessage = "";
      this.responseMessageType = "";

      const formData = new FormData(form);
      formData.append("otp", this.otp);

      await axios
        .post(form.action, formData)
        .then((response) => {
          this.responseMessage = "Success! Redirecting.";
          this.responseMessageType = "alert-success";
          window.location.href = "/login-redirect";
        })
        .catch((error) => {
          this.responseMessage = error.response.data.message;
          this.responseMessageType = "alert-danger";
          spinner.classList.add("d-none");
          button.disabled = false;
        });
    },

    /**
     * Request OTP be sent to secondary device
     */
    async requestSecondaryAuthMethodOtp() {
      this.responseMessage = "";
      this.responseMessageType = "";

      const form = document.getElementById("newMethodForm");
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.responseMessage = response.data.message;
          this.responseMessageType = "alert-success";
        })
        .catch((error) => {
          this.responseMessage = error.response.data.message;
          this.responseMessageType = "alert-danger";
        });
    },

    /**
     * Request OTP be sent again to same device
     */
    async requestNewOtp() {
      this.responseMessage = "";
      this.responseMessageType = "";

      const form = document.getElementById("requestNewOtpForm");
      const formData = new FormData(form);
      await axios
        .post(form.action, formData)
        .then((response) => {
          this.responseMessage = response.data.message;
          this.responseMessageType = "alert-success";
        })
        .catch((error) => {
          this.responseMessage = error.response.data.message;
          this.responseMessageType = "alert-danger";
        });
    }
  }
};
</script>
